













































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import CardWithTitle from '@/components/Card/CardWithTitle.vue'
import Table from '@/components/Table/Table.vue'
import FButton from '@/components/Button/FButton.vue'
import FForm from '@/components/Form/FForm.vue'
import FInput from '@/components/Form/FInput.vue'
import FSelect from '@/components/Form/FSelect.vue'
import CImage from '@/components/Image/CImage.vue'
import Modal from '@/components/Modal/Modal.vue'
import DirectDepositHelp from './DirectDepositHelp.vue'
import { DirectDepositData } from '@/models/DirectDepositData'

import { TableColumn } from '@/models/TableColumn';
import { SelectOption } from '@/models/SelectOption'
import { ApiService } from '@/services/ApiService'
import { ProfileService } from '@/services/ProfileService'
import { AccountHolder } from '@/models/AccountHolder'
import { ServiceCenterPaymentSettings } from '@/models/ServiceCenterPaymentSettings'
import { BusyService } from '@/services/BusyService'
import { ToastService } from '@/services/ToastService'
import { i18n } from '@/main'
import { DynamicTranslationService } from '@/services/DynamicTranslationService'
import { CustomError } from '@/models/CustomError'

@Component({
  components: {
    CardWithTitle, 
    Table,
    FButton, 
    FForm, 
    FInput, 
    FSelect, 
    Modal, 
    DirectDepositHelp, 
    CImage
  }, 
  metaInfo: () => ({
    title: DynamicTranslationService.paymentSettingsTitle
  }),
  beforeRouteEnter: (to, from, next) => { 
    if (ProfileService.enableDirectDeposit){ 
      next();
    }else{
      next('/payment')
    }
  }
})
export default class DirectDeposit extends Vue {
  formData: DirectDepositData = { 
    countryLocation: '',
    routingNumber: '',
    accountNumber: '',
    confirmAccountNumber: '',
    swiftBicCode: '',
    accountType: '',
    accountHolderName: '',
    accountHolderId: '',
    newAccountHolderName: '',
    requestId: '',
    bankName: '',
    currencyTypes: '',
    bankStreetAddress: '',
    bankCity: '',
    bankDistrict: '',
    bankPostalCode: '',
  };

  transitNumber = '';
  institutionNumber = '';

  get tableColumns(): TableColumn[] { 
    return [
      { key: 'type', label: i18n.t('R.LIT_Type').toString()},
      { key: 'details', label: i18n.t('R.LIT_Details').toString()},
    ];
  }

  data: ServiceCenterPaymentSettings[] = [];

  get countryLocationOptions(): SelectOption[] {
    return [
      {
        value: 'US', 
        label: i18n.t('R.LIT_UnitedStates').toString()
      },
      {
        value: 'CA', 
        label: i18n.t('R.LIT_Canada').toString()
      },
    ]
  }

  get accountTypeOptions(): SelectOption[] {
    return [
      { 
        value: '', 
        label: i18n.t('R.LIT_Select').toString()
      }, 
      { 
        value: 'checking', 
        label: i18n.t('R.LIT_Checking').toString()
      }, 
      { 
        value: 'savings', 
        label: i18n.t('R.LIT_Savings').toString()
      }
    ]
  }

  get currencyTypeOptions(): SelectOption[] {
    return [
      { 
        value: '', 
        label: i18n.t('R.LIT_Select').toString()
      }, 
      { 
        label: i18n.t('R.LIT_CanadianDollars').toString(), 
        value: 'CAD'
      }, 
      { 
        label: i18n.t('R.LIT_USDollars').toString(), 
        value: 'USD'
      }
    ]
  }

  get canadaProvinces(): SelectOption[] {
    return [
      { 
        value: '', 
        label: i18n.t('R.LIT_Select').toString()
      }, 
      { value: 'AB', label: 'AB'},
      { value: 'BC', label: 'BC'},
      { value: 'MB', label: 'MB'},
      { value: 'NB', label: 'NB'},
      { value: 'NL', label: 'NL'},
      { value: 'NT', label: 'NT'},
      { value: 'NS', label: 'NS'},
      { value: 'NU', label: 'NU'},
      { value: 'ON', label: 'ON'},
      { value: 'PE', label: 'PE'},
      { value: 'QC', label: 'QC'},
      { value: 'SK', label: 'SK'},
      { value: 'YT', label: 'YT'},
    ]
  }

  get accountHolderNameOptions(): SelectOption[] { 
    const options = this.availableAccountHolderNames
      .map(n => ({value: n.partyNameId, label: n.partyName}));

    options.push({ 
        value: 'NEW', 
        label: this.$t('R.LIT_AddNew').toString()
    })

    return options;
  }

  availableAccountHolderNames: AccountHolder[] = [];

  async mounted() { 
    if (!ProfileService.profile){ 
      return;
    }
    BusyService.showBusy();
    if (['US','CA'].includes(ProfileService.profile.org.countryCode.toUpperCase())){
      this.formData.countryLocation = ProfileService.profile.org.countryCode.toUpperCase();
      await this.confirmRoutingInstitution();
    }
    this.data = await ApiService.post('/api/GetPaymentSettings', { organizationId: ProfileService.profile.org.id })
    this.data = this.data.filter(d => d.payoutLocation === 'North America');
    this.availableAccountHolderNames = await ApiService.post('/api/GetDirectDepositAccountHolderNames', { organizationId: ProfileService.profile.org.id});
    BusyService.hideBusy();
  }

  financialInstitution = '';
  financialInstitutionErrMsg = '';

  async confirmRoutingInstitution() { 
    if (this.formData.countryLocation !== 'US'){
      this.financialInstitution = '';
      this.financialInstitutionErrMsg = '';
      if (this.transitNumber && this.institutionNumber){
        this.formData.routingNumber = '0' + this.institutionNumber + this.transitNumber;
      }      
      return;
    }
    if (!this.formData.routingNumber){ 
      this.financialInstitution = '';
      this.financialInstitutionErrMsg = '';
      return;
    }
    BusyService.showBusy();
    
    try{
      this.financialInstitution = await ApiService.post('/api/GetFinancialInstitution',{ routingNumber: this.formData.routingNumber });
      this.financialInstitutionErrMsg = '';
    }catch(err: any){ 
      this.financialInstitution = '';
      if (err?.data?.publicStatusMessage){
        this.financialInstitutionErrMsg = err.data.publicStatusMessage;
      }else{
        ToastService.dangerToast(i18n.t('R.MES_ServerError').toString())
        throw new CustomError(err, false, 'Reason code not recognized');
      }
    }

    BusyService.hideBusy();
  }

  async onSubmit() { 
    if ((this.$refs.form as FForm).hasFormError){
      return;
    }
    BusyService.showBusy();

    if (this.formData.countryLocation === 'US'){
      this.formData.bankName = this.financialInstitution;
    }
    const currencyTypes = [];
    if (this.formData.countryLocation !== 'US'){
      if (this.usdCurrency){
        currencyTypes.push('USD')
      }
      if (this.cadCurrency){
        currencyTypes.push('CAD')
      }
      this.formData.currencyTypes = currencyTypes.join(',');
    }
    await ApiService.post('/api/SetDirectDeposit',{ data: JSON.stringify(this.formData) });
    BusyService.hideBusy();
    await this.$router.push('/payment');
    ToastService.successToast(this.$t('R.LIT_DirectDepositSuccessfullySubmitted').toString())
  }

  confirmAccountNumberErr= '';
  
  onConfirmAccountNumberInput() { 
    if (this.formData.confirmAccountNumber !== this.formData.accountNumber){
      this.confirmAccountNumberErr = this.$t('R.MES_AccountNumbersDontMatch').toString();
    }else{ 
      this.confirmAccountNumberErr = '';
    }
  }

  accountNumberErr = ''
  validateAccountNumber(){ 
    if (!this.formData.accountNumber){
      this.accountNumberErr = '';
    }else if (this.formData.accountNumber.match(/^[0-9]+$/) === null){
      this.accountNumberErr = this.$t('R.MES_NumericDigitsOnly').toString();
    }else if (!(this.formData.accountNumber.length >= 2 && this.formData.accountNumber.length <= 17)){ 
      this.accountNumberErr = this.$t('R.MES_AccountLengthErrorMsg').toString();
    }else{ 
      this.accountNumberErr = '';
    }
  }

  confirmSwiftBicCodeErr = ''
  onConfirmSwiftBicCode() { 
    if (!this.formData.swiftBicCode){
      this.confirmSwiftBicCodeErr = '';
    }else if (!(this.formData.swiftBicCode.length === 8 || this.formData.swiftBicCode.length === 11)){
      this.confirmSwiftBicCodeErr = this.$t('R.MES_SwiftBicCodeMessage').toString()
    }else{ 
      this.confirmSwiftBicCodeErr = '';
    }
  }

  onAccountHolderInput() { 
    if (this.formData.accountHolderId === 'new' || !this.formData.accountHolderId ){
      this.formData.accountHolderName = '';
    }else{ 
      this.formData.accountHolderName = this.availableAccountHolderNames.find(n => n.partyNameId === this.formData.accountHolderId)?.partyName || '';
    }
  }

  cadCurrency = false;
  usdCurrency = false;

}
